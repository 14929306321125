<template>
    <div>
        <b-modal id="modal-global-rules" size="lg" :okTitle="$t('button.save')" okVariant="success" scrollable :title="$t('roles.title.global_permissions')">
            <div class="row ">
                <div class="col-6 mb-10"
                v-for="(category, index) in rulesCategories"
                :key="index"
                >
                <h3 class="font-size-lg text-dark font-weight-bold mb-6">
                    <span class="svg-icon svg-icon-md svg-icon-primary mr-1">
                        <inline-svg src="/media/svg/icons/Communication/Group.svg"/>
                    </span>
                    {{ category.category }}
                </h3>
                    <div
                    v-for="(rule, ruleIndex) in category.rules"
                    :key="ruleIndex"
                    >
                        <b-form-checkbox :value="rule" v-model="haveRulesLocal">{{ rule.name }}</b-form-checkbox>
                        <div class="separator separator-dashed  my-2"></div>
                    </div>
                </div>
            </div>
            <template #modal-footer="{ cancel }">
                <b-button variant="secondary" @click="cancel()" class="mr-3">
                    {{ $t('button.cancel') }}
                </b-button> 
                <b-button variant="success" @click="editHaveRules()">
                    {{ $t('roles.button.save_rule') }}
                </b-button>         
            </template>
        </b-modal>
    </div>
</template>
<script>
export default {
  name: "user_overview",
  props: {
    roleID: null,
    rulesCategories: null,
    haveRules: null,
    loading: null
  },
  mounted() {
  
  },
  methods: {
      editHaveRules(){
          this.$parent.editHaveRules();
      }
  },
  computed: {
    haveRulesLocal: {
        get: function() {
            return this.haveRules
        },
        set: function(value) {
             this.$parent.updateHaveRulesList(value);
        }
    }
  }
};
</script>