<template>
    <div class="col-md-4">
        <div class="card card-custom" >
          <div class="card-header">
            <div class="card-title">
                <h5 class="card-label">
                  {{ role.name }}
                </h5>
            </div>
            <div class="card-toolbar"> 
                <a class="btn btn-link btn-sm btn btn-clean btn-hover-light-danger btn-sm btn-icon" v-b-tooltip.top="$t('roles.title.delete_role')" v-b-modal.modal-role-delete @click="setRole(role)"><i class="ki ki-bold-close icon-sm text-danger"></i></a>
                <a class="btn btn-link btn-sm btn btn-clean btn-hover-light-primary btn-sm btn-icon" v-b-tooltip.top="$t('button.settings')" v-b-modal.modal-role-edit @click="setRole(role)"><i class="ki ki-gear icon-md"></i></a>
                <a class="btn btn-link btn-sm btn btn-clean btn-hover-light-primary btn-sm btn-icon font-weight-bold" v-b-tooltip.top="$t('roles.title.num_of_users')" @click.prevent="setFilter(role.role_id)">{{ role.count }}</a>
            </div>
          </div>
          <div class="card-body pt-5">
              <div class="d-flex align-items-center">
                <span class="bullet bullet-bar bg-primary align-self-stretch"></span>
                <div class="d-flex flex-column flex-grow-1 ml-5">
                  <a href="#" class="text-dark-75">{{ $t('roles.title.global_permissions') }}</a>
                </div>
                <b-button class="btn btn-clean btn-link btn-hover-light-primary btn-xs btn-icon" variant="clean" v-b-modal.modal-global-rules @click="setRulesType('core'); setRole(role);">
                  <i class="fa fa-lock text-primary"></i>
                </b-button>
              </div>
              <hr />
              <span class="text-muted mt-3 font-weight-bold font-size-sm">{{ $t('title.modules') }}:</span>
              <div class="d-flex align-items-center mt-3">
                <span class="bullet bullet-bar bg-default align-self-stretch"></span>
                <div class="d-flex flex-column flex-grow-1 ml-5">
                  <a href="#" class="text-dark-75">Evidence klientů</a>
                </div>
                <a href="#" class="btn btn-hover-light-primary btn-xs btn-icon" v-b-modal.modal-global-rules @click="setRulesType('clients'); setRole(role);">
                  <i class="fa fa-lock text-primary"></i>
                </a>
              </div>
              <div class="d-flex align-items-center mt-3">
                <span class="bullet bullet-bar bg-default align-self-stretch"></span>
                <div class="d-flex flex-column flex-grow-1 ml-5">
                  <a href="#" class="text-dark-75">Jídelní terminál</a>
                </div>
                <a href="#" class="btn btn-hover-light-primary btn-xs btn-icon" v-b-modal.modal-global-rules @click="setRulesType('kitchen'); setRole(role);">
                  <i class="fa fa-lock text-primary"></i>
                </a>
              </div>
          </div>
        </div>
    </div>
</template>
<script>
export default {
  props: {
    role: Object
  },
  methods: {
  	setRole(role){
    	this.$parent.setRole(role);
    },
    setRulesType(type){
    	this.$parent.setRulesType(type);
    },
    setFilter(id){
      this.$store.commit('setUserSearch', {search: "", groups: id});
      this.$router.push({ name: 'users'});
    }
  }
};
</script>